import React, { useState } from 'react';
import axios from 'axios';
import './UploadForm.css'; // 确保创建一个新的CSS文件并导入

function UploadForm() {
    const [file, setFile] = useState(null);
    const [uploadResponse, setUploadResponse] = useState('');
    const [uploading, setUploading] = useState(false);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        setUploadResponse('');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!file) return;

        setUploading(true);
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setUploadResponse(` ${response.data.url}`);
        } catch (error) {
            setUploadResponse(`Error uploading file: ${error.message}`);
        } finally {
            setUploading(false);
        }
    };

    return (
        <div className="upload-form-container">
            <h1 className="upload-form-title">Upload File</h1>
            <form onSubmit={handleSubmit} className="upload-form">
                <div className="upload-input-wrapper">
                    <input
                        type="file"
                        onChange={handleFileChange}
                        className="upload-input"
                        disabled={uploading}
                    />
                </div>
                <button
                    type="submit"
                    className="upload-button"
                    disabled={uploading}
                >
                    {uploading ? 'Uploading...' : 'Upload'}
                </button>
                {uploadResponse && (
                    <div className="upload-response">
                        {uploadResponse}
                    </div>
                )}
            </form>
        </div>
    );
}

export default UploadForm;
